import { HttpClient } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { apiUrlWelderFroniusJobs } from "../../constants/api-url.constant";
import { ApiWelderJobInterface } from "../../interfaces/api-welder/api-welder-jobs.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiResourceWelderService {
  constructor(
    protected httpClient: HttpClient,
  ) { }

  getFroniusJobs$(deviceId: string): Observable<ApiWelderJobInterface[]> {
    return this.httpClient.get<ApiWelderJobInterface[]>(apiUrlWelderFroniusJobs(deviceId));
  }
}
