import { Routes } from '@angular/router';
import { AllParametersResolver } from '../../../shared/resolvers/all-parameters.resolver';
import { AvailableViewResolver } from '../../../shared/resolvers/available-view.resolver';
import { DeviceResolver } from '../../../shared/resolvers/device.resolver';
import { RobotStatusOverallResolver } from '../../../shared/resolvers/robot-status-overall.resolver.service';
import { RobotStatusResolver } from '../../../shared/resolvers/robot-status.resolver.service';
import { RobotFroniusPageComponent } from './robot-fronius-page.component';

const ROBOT_FRONIUS_ROUTES: Routes = [
  {
    path: '',
    component: RobotFroniusPageComponent,
    resolve: {
      device: DeviceResolver,
      availableViews: AvailableViewResolver,
      parameters: AllParametersResolver,
      robotStatus: RobotStatusResolver,
      robotStatusOverall: RobotStatusOverallResolver,
    },
  },
];

export default ROBOT_FRONIUS_ROUTES;
