import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatTableModule } from "@angular/material/table";
import { ActivatedRoute, Data } from "@angular/router";
import { Observable, switchMap, tap } from "rxjs";
import { catchError } from "rxjs/operators";
import { PageHeaderComponent } from "../../../shared/components/page-header/page-header.component";
import { WelderJobInterface, WelderJobsService } from "./welder-jobs.service";

@Component({
  selector: 'app-welder-jobs',
  standalone: true,
  imports: [
    CommonModule,
    MatTableModule,
    PageHeaderComponent,
  ],
  templateUrl: './welder-jobs.component.html',
  styleUrl: './welder-jobs.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelderJobsComponent {
  jobs$: Observable<WelderJobInterface[]>;
  selectedJob: WelderJobInterface | null = null;

  constructor(
    welderJobsService: WelderJobsService,
    activatedRoute: ActivatedRoute,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    this.jobs$ = activatedRoute.data.pipe(
      switchMap((data: Data) => {
        this.selectedJob = null;
        changeDetectorRef.markForCheck();

        return welderJobsService.getFroniusJobs$(data.device.id);
      }),
      tap((jobs: WelderJobInterface[]) => {
        if (jobs.length > 0) {
          this.selectedJob = jobs[0];
          changeDetectorRef.markForCheck();
        }
      }),
      catchError((err) => {
        console.error('Error loading welder jobs:', err);
        return [];
      }),
    );
  }
}
