import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AvailableViewsStore } from '../../available-views/available-views.store';
import { CurrentViewStore } from '../../../../store/current-view/current.view.store';
import { DeviceShortInterface } from '../../../../shared/interfaces/device.interface';
import { ViewInterface } from '../../available-views/view.interface';
import { UrlsService } from '../../../../shared/services/urls.service';
import { routingPathSlaveFronius } from "../../../../shared/constants/routing-path.constant";

export interface GetUrlInterface {
  device: DeviceShortInterface;
  url: string;
  isViewAvailable: boolean;
};


@Injectable()
export class DevicesDropdownNavigationService {
  constructor(
    private router: Router,
    private currentViewStore: CurrentViewStore,
    private availableViewsStore: AvailableViewsStore,
    private urlsService: UrlsService,
  ) {}

  async navigateToRobot(data: Omit<GetUrlInterface, 'device'>): Promise<void> {
    if (data.isViewAvailable) {
      const rrs = this.router.routeReuseStrategy;
      const prev = rrs.shouldReuseRoute;
      rrs.shouldReuseRoute = () => false;
      await this.router.navigateByUrl(data.url).catch(() => {});
      rrs.shouldReuseRoute = prev;
    } else {
      void this.router.navigateByUrl(data.url);
    }
  }

  async getUrl(device: DeviceShortInterface): Promise<GetUrlInterface>; // master device
  async getUrl(device: string): Promise<Omit<GetUrlInterface, 'device'>>; // slave device
  async getUrl(device: DeviceShortInterface | string): Promise<Partial<GetUrlInterface>> {
    const currentView = this.currentViewStore.getCurrentView();
    const deviceId = typeof device === 'string' ? device : device.id;
    const isViewAvailable: boolean = !!currentView &&
      await this.isCurrentViewAvailableInNextDevice(currentView, deviceId);

    const url: string = (isViewAvailable && currentView) ?
      currentView.base_url + deviceId :        // reload
      typeof device === "string" ?
        routingPathSlaveFronius(deviceId) :    // navigate to slave root page
        this.urlsService.getRoutePath(device); // navigate to robot root page

    return typeof device === "string" ? { url, isViewAvailable } : { device, url, isViewAvailable };
  }

  async isCurrentViewAvailableInNextDevice(
    currentView: ViewInterface,
    nextDeviceId: string,
  ): Promise<boolean> {
    const availableViews$ = this.availableViewsStore.resolve(nextDeviceId);
    const availableViews = await firstValueFrom(availableViews$);
    return availableViews.some(nextView => nextView.base_url === currentView.base_url);
  }
}
