import { Routes } from '@angular/router';
import { AllParametersResolver } from '../../../shared/resolvers/all-parameters.resolver';
import { AvailableViewResolver } from '../../../shared/resolvers/available-view.resolver';
import { DeviceResolver } from '../../../shared/resolvers/device.resolver';
import { RobotStatusOverallResolver } from '../../../shared/resolvers/robot-status-overall.resolver.service';
import { RobotStatusResolver } from '../../../shared/resolvers/robot-status.resolver.service';
import { WelderWeldsPageComponent } from './welder-welds-page.component';

const WELDER_WELDS_ROUTES: Routes = [
  {
    path: '',
    component: WelderWeldsPageComponent,
    resolve: {
      device: DeviceResolver,
      availableViews: AvailableViewResolver,
    },
  },
];

export default WELDER_WELDS_ROUTES;
