<div class="page-robot-details kgn-container">
	<app-page-header help="fanuc" i18n>Robot details</app-page-header>

	<div class="tiles kgn-grid kgn-gap-2">
		<div class="tile box robot-summary kgn-grid kgn-gap-2">
			<div class="tile__intro">
				<app-tile-header
					[title]="device.name"
					[description]="device.description"
					(descriptionChange)="onEditedDescription($event)"
					[editable]="true"
					i18n
				>Robot description</app-tile-header>
				<img [src]="device.tpe.image" [alt]="device.name">
				<app-tile-program
					*ngIf="parametersMap?.get(SLUG.PRODUCTION_PROGRAM_NAME) as parameterProgramName"
					title="Production program"
					i18n-title
					[name]="parameterProgramName.value"
					[time]="parameterProgramName.lastChanged"
				></app-tile-program>
				<app-tile-program
					*ngIf="parametersMap?.get(SLUG.CURRENT_PROGRAM_NAME) as parameterProgramName"
					title="Current program"
					i18n-title
					[name]="parameterProgramName.value"
				></app-tile-program>
				<ng-container *ngIf="robotStatus[device.status_id].overall_status_id">
					<app-robot-status-badge
						[robotStatus]="robotStatus"
						[robotStatusOverall]="robotStatusOverall"
						[showIcon]="true"
						[device]="device"
						class="robot-status__badge"
					></app-robot-status-badge>
				</ng-container>
			</div>

			<div class="tile__items">
				<div class="tile__item customer">
					<div class="title" i18n>Customer</div>
					<div class="desc">{{device.client_name}}</div>
				</div>
				<div class="tile__item site">
					<div class="title" i18n>Site</div>
					<div class="desc editable" (click)="editSite($event)">
						{{device.site}}
						<div class="edit-icon"></div>
					</div>
				</div>
				<div class="tile__item type">
					<div class="title" i18n>Robot type</div>
					<div class="desc">{{device.robot_type || device.tpe.name}}</div>
				</div>
				<div class="tile__item sn">
					<div class="title" i18n>Robot S/N</div>
					<div class="desc">{{device.robot_serial_number}}</div>
				</div>
				<div class="tile__item soft">
					<div class="title" i18n>Software version</div>
					<div class="desc">{{device.software_version}}</div>
				</div>
				<div class="tile__item ip">
					<div class="title" i18n>Robot IP address</div>
					<div class="desc">{{device.ip_address}}</div>
				</div>
				<div class="tile__item separator"></div>
				<div class="tile__item alerts">
					<div class="title" i18n>Alerts</div>
					<div class="desc">
						<app-alerts-counter
							[value]="device.alarm_count"
							[deviceId]="deviceId"
						></app-alerts-counter>
					</div>
				</div>
				<div class="tile__item backup">
					<div class="title" i18n>Last backup</div>
					<div class="desc">{{device.last_backup_timestamp ? (device.last_backup_timestamp | timeFormat) : '--'}}</div>
				</div>
				<div class="tile__item separator"></div>
				<div class="tile__item install-date">
					<div class="title" i18n>Robot installation date</div>
					<div class="desc">
						{{deviceServiceDates.robot_installation_date ? (deviceServiceDates.robot_installation_date | timeFormat: AggregationTypeEnum.DAY) : '--'}}
						<mat-icon
							(click)="editDate($event, 'robot_installation_date')"
							class="material-icons-outlined icon-button">today</mat-icon>
					</div>
				</div>
				<div class="tile__item maintenance">
					<div class="title" i18n>Last maintenance service</div>
					<div class="desc">
						{{deviceServiceDates.last_maintenance_service ? (deviceServiceDates.last_maintenance_service | timeFormat: AggregationTypeEnum.DAY) : '--'}}
						<mat-icon
							(click)="editDate($event, 'last_maintenance_service')"
							class="material-icons-outlined icon-button">today</mat-icon>
					</div>
				</div>
				<div class="tile__item service-date">
					<div class="title" i18n>Next service date</div>
					<div class="desc" [ngClass]="{'font_error': isRobotServiceExpired}">
						{{deviceServiceDates.next_service_date ? (deviceServiceDates.next_service_date | timeFormat: AggregationTypeEnum.DAY) : '--'}}
						<mat-icon
							(click)="editDate($event, 'next_service_date')"
							class="material-icons-outlined icon-button">today</mat-icon>
					</div>
				</div>
				<div class="tile__item separator"></div>
				<div class="tile__item controller-time">
					<div class="title" i18n>Robot controller time</div>
					<div class="desc" [ngClass]="{'font_error': !isRobotTimeSynchronized}">{{device.robot_controller_time ? (device.robot_controller_time | timeFormat) : '--'}}</div>
				</div>
				<div class="tile__item local-time">
					<div class="title" i18n>Local / site time</div>
					<div class="desc">{{localTimeStamp | timeFormat}}</div>
				</div>
				<div class="tile__item separator" *ngIf="hasSpeedOverride"></div>
				<div class="tile__item speed" *ngIf="hasSpeedOverride">
					<div class="title" i18n>Speed override</div>
					<div class="desc">{{parametersMap?.get(SLUG.SPEED_OVERRIDE_NAME)?.value ? (parametersMap?.get(SLUG.SPEED_OVERRIDE_NAME)?.value + '%') : '--'}}</div>
				</div>
			</div>
		</div>

		<div class="tile box robot-current-shift no-error-score">
			<div class="tile__charts kgn-grid kgn-gap-2">
				<div class="tile__charts-header">
					<div class="title" i18n>Current shift</div>
					<div class="desc"><span i18n>since</span>: {{dashboardService.getRobotCurrentShitDuration(device, robotStatus, robotStatusOverall)}}</div>
				</div>
				<div class="chart">
					<app-gauge
						[range]="[0, 100]"
						[value]="device.shift_stats?.working_percent ?? null"
						[displayRange]="false"
						[displayEmpty]="dashboardService.isRobotOffline(device, robotStatus, robotStatusOverall)
							|| !dashboardService.isRobotInCurrentShift(device)"
						title="In production"
						i18n-title
						[isRobotOffline]="dashboardService.isRobotOffline(device, robotStatus, robotStatusOverall)
							|| !dashboardService.isRobotInCurrentShift(device)"
					></app-gauge>
				</div>
				<div class="chart">
					<app-arrow
						[stats]="device.shift_stats ?? null"
						title="Fault-related stops"
						i18n-title
						[isRobotOffline]="dashboardService.isRobotOffline(device, robotStatus, robotStatusOverall)
						|| !dashboardService.isRobotInCurrentShift(device)"
					></app-arrow>
				</div>
			</div>
		</div>
		<div class="tile box kgn-tablet-desktop-only timeline-chart robot-in-production">
			<div class="tile__charts-header">
				<div class="title">
					<ng-container i18n>Robot in Production</ng-container>
					<span *ngIf="!isCurrentShift" [style.font-weight]="500" i18n> (no current shift)</span>
				</div>
			</div>
			<app-report-overall *ngIf="reports" [reports]="reports"></app-report-overall>
			<div class="tile__action">
        @let slave_devices = device.slave_devices ?? [];
        @for (slave of slave_devices; track slave.id) {
          <a class="btn primary" [href]="getRoutingPathWelder(slave.id)" [title]="slave.name">
            <span i18n>Welder details</span>
          </a>
        }
				<a [href]="routingPathUtilization" class="btn primary">
					<span i18n>Utilisation details</span>
				</a>
			</div>
		</div>

		<div class="page-button mobile-only">
			<a [href]="routingPathUtilization" class="btn primary">
				<span i18n>Robot utilisation details</span>
			</a>
		</div>
	</div>
</div>
