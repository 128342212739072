import { Routes } from '@angular/router';
import { AllParametersResolver } from '../../../shared/resolvers/all-parameters.resolver';
import { AvailableViewResolver } from '../../../shared/resolvers/available-view.resolver';
import { DeviceResolver } from '../../../shared/resolvers/device.resolver';
import { RobotStatusOverallResolver } from '../../../shared/resolvers/robot-status-overall.resolver.service';
import { RobotStatusResolver } from '../../../shared/resolvers/robot-status.resolver.service';
import { WelderJobsPageComponent } from './welder-jobs-page.component';

const WELDER_JOBS_ROUTES: Routes = [
  {
    path: '',
    component: WelderJobsPageComponent,
    resolve: {
      device: DeviceResolver,
      availableViews: AvailableViewResolver,
    },
  },
];

export default WELDER_JOBS_ROUTES;
