<div class="kgn-container">
  <app-page-header help="welder-jobs" icon="settings" i18n>Job viewer</app-page-header>

  <div class="tiles kgn-grid kgn-gap-2 kgn-xl-grid-golden-ratio">
    <div class="tile box jobs-table-box">
      <table mat-table [dataSource]="jobs$" class="mat-mdc-table--no-shadow">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef colspan="2" i18n>Saved Jobs</th>
          <td mat-cell *matCellDef="let job">{{ job.id }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <td mat-cell *matCellDef="let job">{{ job.name }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['id']"></tr>
        <tr mat-row
          *matRowDef="let job; columns: ['id', 'name'];"
          class="clickable-row"
          [class.active]="selectedJob === job"
          (click)="selectedJob = job"
        ></tr>
      </table>
    </div>
    <div class="tile box jobs-table-box">
      <table
        mat-table
        [dataSource]="selectedJob?.parameters || []"
        class="mat-mdc-table--no-shadow"
        [hidden]="!selectedJob"
      >
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Parameter name</th>
          <td mat-cell *matCellDef="let item">{{ item.name }}</td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>Value</th>
          <td mat-cell *matCellDef="let item">{{ item.value }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['name', 'value']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['name', 'value'];"></tr>
      </table>
    </div>
  </div>
</div>
