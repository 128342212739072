import { Injectable } from '@angular/core';
import { map, Observable } from "rxjs";
import { ApiWelderJobInterface } from "../../../shared/modules/api/interfaces/api-welder/api-welder-jobs.interface";
import { ApiResourceWelderService } from "../../../shared/modules/api/services/api-resource-welder/api-resource-welder.service";

export interface WelderJobInterface {
  id: string;
  name: string;
  type: string;
  created: Date;
  modified: Date;
  parameters: WelderJobParameterInterface[];
}

export interface WelderJobParameterInterface {
  name: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class WelderJobsService {
  constructor(
    protected apiResourceWelderService: ApiResourceWelderService,
  ) { }

  getFroniusJobs$(deviceId: string): Observable<WelderJobInterface[]> {
    return this.apiResourceWelderService.getFroniusJobs$(deviceId).pipe(
      map((jobs: ApiWelderJobInterface[]) =>
        jobs.map((job: ApiWelderJobInterface) => {
          const parameters: WelderJobParameterInterface[] = [];
          for (const parameter of job.values) {
            const name = (parameter.pretty_name || parameter.name) + "";
            const value = parameter.unit ? `${parameter.value} [${parameter.unit}]` : `${parameter.value}`;
            parameters.push({ name, value });
          }
          return {
            id: +job.job_id + "",
            name: job.name || $localize`(no name)`,
            type: job.type + "",
            created: new Date(job.created),
            modified: new Date(job.modified),
            parameters
          };
        }),
      ),
    );
  }
}
