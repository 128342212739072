import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoaderComponent } from "../../../shared/components/loader/loader.component";
import { LogsFanucCategoryPipe } from "../../diagnostic/logs/logs-fanuc/pipes/logs-fanuc-category/logs-fanuc-category.pipe";
import { LogsFanucFiltersComponent } from "../../diagnostic/logs/logs-fanuc/components/logs-fanuc-filters/logs-fanuc-filters.component";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable
} from "@angular/material/table";
import { MatIcon } from "@angular/material/icon";
import { MatPaginator } from "@angular/material/paginator";
import { CommonModule, NgForOf, NgIf } from "@angular/common";
import { NgScrollbarModule } from "ngx-scrollbar";
import { TableGeneratorModel } from "../../../shared/modules/table/classes/table-generator.model";

@Component({
  selector: 'app-welder-welds',
  standalone: true,
  imports: [
    CommonModule,
    LoaderComponent,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatIcon,
    MatPaginator,
    MatRow,
    MatRowDef,
    MatTable,
    NgScrollbarModule,
    LogsFanucCategoryPipe,
    LogsFanucFiltersComponent,
  ],
  templateUrl: './welder-welds.component.html',
  styleUrl: './welder-welds.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelderWeldsComponent {
  table: TableGeneratorModel<unknown[]>;
}
