<div class="errors">
  <div class="kgn-container">
    <div style="text-align:right">TODO: filters</div>
    <div class="kg-card kgn-mt-1 kgn-mb-2 kgn-height-auto">
      <div class="kg-title logs-ur-filters__label">Welds logbook</div>
      <div class="grid grid--gap">
        <div class="grid__item">
          <ng-scrollbar
            #scrollbar
            [autoHeightDisabled]="false"
            [track]="'all'"
            [visibility]="'hover'"
            class="logs-ur-table"
          >
            <div
              class="logs-ur-loading"
              [class.logs-ur-loading--active]="table.isLoading"
            >
              <app-loader [height]="24" class="logs-ur-loading__loader"></app-loader>
            </div>
            <table
              mat-table
              [dataSource]="table.data"
            >
              <ng-container
                *ngFor="let column of table.columnsNames; let colIdx = index"
                [matColumnDef]="column"
              >
                <th
                  *matHeaderCellDef
                  mat-header-cell
                  [style.min-width]="table.columns[colIdx].width"
                  [style.text-align]="table.columns[colIdx].textAlign"
                >
                  {{ table.columns[colIdx].name }}
                </th>
                <td
                  *matCellDef="let element"
                  [style.min-width]="table.columns[colIdx].width"
                  [style.text-align]="table.columns[colIdx].textAlign"
                  mat-cell
                >
                  @let columnApiName = table.columns[colIdx].apiName;
                  @if (columnApiName) {
                    {{ element[columnApiName] }}
                  }
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="table.columnsNames; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: table.columnsNames;"></tr>
              <tr class="mat-row" *matNoDataRow>
                <td
                  [attr.colspan]="table.columnsNames.length"
                  class="mat-cell"
                  [style.height]="'80px'"
                >
                  <div align="center" *ngIf="!table.isLoading" i18n>No logs</div>
                </td>
              </tr>
            </table>
          </ng-scrollbar>

          <mat-paginator
            #paginator="matPaginator"
            [pageSize]="table.pageSize"
            [length]="table.countAll"
            [disabled]="table.isLoading"
            hidePageSize
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
