import { Component } from '@angular/core';
import { WelderJobsComponent } from "../../../modules/welder/welder-jobs/welder-jobs.component";

@Component({
  selector: 'app-welder-jobs-page',
  template: `<app-welder-jobs></app-welder-jobs>`,
  standalone: true,
  imports: [WelderJobsComponent],
})
export class WelderJobsPageComponent {}
