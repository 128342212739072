import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatRippleModule } from "@angular/material/core";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { Subject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { DeviceShortInterface } from '../../../shared/interfaces/device.interface';
import { FormSearchComponent } from '../../../shared/modules/form/components/form-search/form-search.component';
import { DeviceStatusIconComponent } from '../../../shared/components/device-status-icon/device-status-icon.component';
import { DevicesDropdownDataService } from './services/devices-dropdown-data.service';
import { DevicesDropdownNavigationService, GetUrlInterface } from './services/devices-dropdown-navigation.service';
import { ClickOutsideDirective } from "../../../shared/directives/click-outside/click-outside.directive";
import { MatIcon } from "@angular/material/icon";

interface DeviceDropdownOptionInterface {
  label: string;
  value: string | number | null | object;
  additionalData: GetUrlInterface;
  slaves?: {
    label: string;
    value: string;
    additionalData: Omit<GetUrlInterface, 'device'>;
  }[];
}

@Component({
  standalone: true,
  selector: 'app-device-dropdown',
  templateUrl: 'devices-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: [
    './devices-dropdown.component.scss',
    '../../../shared/modules/form/components/form-select/form.scss',
    '../../../shared/modules/form/components/form-select/form-select.component.scss',
  ],
  imports: [
    ClickOutsideDirective,
    CommonModule,
    DeviceStatusIconComponent,
    FormSearchComponent,
    MatProgressSpinnerModule,
    MatRippleModule,
    NgScrollbarModule,
    RouterModule,
    MatIcon,
  ],
  providers: [
    DevicesDropdownDataService,
    DevicesDropdownNavigationService,
  ],
})
export class DevicesDropdownComponent implements OnInit, OnDestroy {
  @Input('currentDeviceId') set currentDeviceIdSetter(value: string) {
    this.currentDeviceId = value;
    this.getLabel();
  }

  currentDeviceId: string;
  options: DeviceDropdownOptionInterface[];
  optionsFiltered: DeviceDropdownOptionInterface[];
  autocompleteFormControl: FormControl<string | null>;
  devices: DeviceShortInterface[];
  isDropdownOpened = false;
  label = '';
  error = '';

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private dropdownDataService: DevicesDropdownDataService,
    private dropdownNavigationService: DevicesDropdownNavigationService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.autocompleteFormControl = new FormControl('');
    this.autocompleteFormControl.valueChanges.pipe(
      takeUntil(this.destroy$),
    ).subscribe(
      (value: string | null) => this.filterOptions(value),
    );
    this.fetchStatuses();
  }

  filterOptions(keyword: string | null): void {
    if (!keyword) {
      this.optionsFiltered = this.options;
      return;
    }
    keyword = keyword.toLowerCase();

    this.optionsFiltered = [];
    this.options.forEach((option: DeviceDropdownOptionInterface) => {
      const filteredWord = option.label.toLowerCase();
      if (filteredWord.includes(keyword)) {
        this.optionsFiltered.push(option);
        return;
      }

      let slaves = option.additionalData.device.slave_devices?.filter(
        (slave) => slave.name.toLowerCase().includes(keyword),
      );
      if (slaves?.length) {
        this.optionsFiltered.push({
          ...option,
          additionalData: {
            ...option.additionalData,
            device: {
              ...option.additionalData.device,
              slave_devices: slaves,
            },
          }
        });
      }
    });
  }

  fetchStatuses() {
    this.dropdownDataService.getDropdownDevices$().pipe(
      takeUntil(this.destroy$),
      mergeMap(async (devices: DeviceShortInterface[]) => {
        const options = await this.getFormSelectOptions(devices);
        return { devices, options };
      }),
    ).subscribe({
      next: ({ devices, options }) => {
        this.devices = devices;
        this.options = options;
        this.filterOptions(this.autocompleteFormControl.value);
        this.getLabel();
        this.changeDetectorRef.detectChanges();
      },
      error: (err) => {
        this.error = (err ? err + '' : null) || $localize`Unknown error`;
      },
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  onLinkClick(event: MouseEvent, option: { additionalData: Omit<GetUrlInterface, 'device'> }): void {
    event.preventDefault();
    void this.dropdownNavigationService.navigateToRobot(option.additionalData);
    this.onDropdownToggle(true);
  }

  onDropdownToggle(close: boolean): void {
    this.isDropdownOpened = !close;
    if (this.isDropdownOpened) {
      this.fetchStatuses();
    }
    this.changeDetectorRef.detectChanges();
  }

  private getLabel(): void {
    this.label = '--';
    if (this.options) {
      for (const option of this.options) {
        if (option.value === this.currentDeviceId) {
          this.label = option.label;
          return;
        }
        if (option.slaves) {
          for (const slave of option.slaves) {
            if (slave.value === this.currentDeviceId) {
              this.label = slave.label;
              return;
            }
          }
        }
      }
    }
  }

  private async getFormSelectOptions(
    devices: DeviceShortInterface[],
  ): Promise<DeviceDropdownOptionInterface[]> {
    return Promise.all(
      devices.map(async (device: DeviceShortInterface) => ({
        label: `${device.name} ${device.robot_serial_number}`,
        value: device.id,
        additionalData: await this.dropdownNavigationService.getUrl(device),
        slaves: device.slave_devices?.length ? await Promise.all(
          device.slave_devices.map(async (slave) => ({
            label: slave.name,
            value: slave.id,
            additionalData: await this.dropdownNavigationService.getUrl(slave.id),
          })),
        ) : undefined,
      })),
    );
  }
}
