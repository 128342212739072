import { Injectable } from '@angular/core';
import { MathService } from 'src/app/shared/services/math/math.service';
import { ROBOT_SLUG_FRONIUS } from "../../../shared/constants/robot-slug-fronius.constant";
import { RobotStatusFanucEnum } from '../../../shared/enums/robot-status-fanuc.enum';
import { ApiParameterUpdateInterface } from '../../../shared/interfaces/api-parameter-update.interface';
import { DeviceInterface } from '../../../shared/interfaces/device.interface';
import { DictionaryInterface } from '../../../shared/interfaces/dictionary.interface';
import { ApiSingleParameterInterface } from '../../../shared/modules/api/interfaces/api-parameters/api-single-paramter.interface';
import { ParameterViewGeneratorService } from '../../../shared/services/parameters/parameter-view-generator.service';
import { RobotAbstractService } from '../robot-abstract.service';

@Injectable()
export class RobotFroniusService extends RobotAbstractService {
  SLUG: typeof ROBOT_SLUG_FRONIUS = ROBOT_SLUG_FRONIUS;

  constructor(
    protected parameterViewGeneratorService: ParameterViewGeneratorService,
    protected mathService: MathService,
  ) {
    super(parameterViewGeneratorService, mathService);
  }

  updateParameter(update: ApiParameterUpdateInterface, _device: DeviceInterface): void {
    this.updateDefault(update);
  }

  setInitialParametersData(
    parametersDefinitions: DictionaryInterface<ApiSingleParameterInterface>,
  ): void {
    const setParameter = (slug: string, extra?: object) => {
      const def = parametersDefinitions[slug];
      this.parametersMap.set(slug, {
        slug,
        name: def?.name,
        unit: def?.unit?.[0] ?? '',
        type: def?.tpe,
        value: null,
        lastChanged: null,
        ...extra,
      });
    };

    setParameter(this.SLUG.LAST_WELD);
  }

  isRobotOff(): boolean {
    return (
      this.robotStatusState$.value === RobotStatusFanucEnum.OFFLINE ||
      this.robotStatusState$.value === RobotStatusFanucEnum.CONNECTOR_OFFLINE
    );
  }
}
