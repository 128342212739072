import { Component } from '@angular/core';
import { RobotFroniusComponent } from "../../../modules/robot/robot-fronius/robot-fronius.component";

@Component({
  selector: 'app-robot-fronius-page',
  template: `<app-robot-fronius></app-robot-fronius>`,
  standalone: true,
  imports: [RobotFroniusComponent],
})
export class RobotFroniusPageComponent {}
