import { Component } from '@angular/core';
import { RobotFroniusComponent } from "../../../modules/robot/robot-fronius/robot-fronius.component";
import { WelderWeldsComponent } from "../../../modules/welder/welder-welds/welder-welds.component";

@Component({
  selector: 'app-welder-welds-page',
  template: `<app-welder-welds></app-welder-welds>`,
  standalone: true,
  imports: [WelderWeldsComponent],
})
export class WelderWeldsPageComponent {}
